// src/components/ServiceCard.js
import React from 'react';
import './ServiceCard.css';

const ServiceCard = ({ imageSrc, title, description }) => {
  return (
    <div className="service-card">
      <div className="service-card-img-container">
        <img src={imageSrc} alt={title} className="service-card-img" />
      </div>
      <h3 className="service-card-title">{title}</h3>
      <p className="service-card-description">{description}</p>
    </div>
  );
};

export default ServiceCard;
