// AboutUs.js
import React from 'react';
import './AboutUs.css';
import houseImage from '../../assets/house_image.jpeg'; // replace with actual image path

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="image-container">
        <img src={houseImage} alt="House"  />
      </div>
      <div className="content-container">
        <h1>About Us</h1>
        <hr className="underline" />
        <p>
          Welcome to our real estate brokerage. We are dedicated to helping you
          find your dream home by offering expert guidance and personalized service.
          Whether you're buying, selling, or renting, our experienced team is here
          to assist you every step of the way.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
