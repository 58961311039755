import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome CSS
import "./Footer.css"; // Import your custom CSS

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-follow">
        <h3>Follow Us</h3>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/profile.php?id=61565594274172&mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://x.com/DivinerealtyIn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/divinerealty/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a
            href="https://www.instagram.com/divinerealty.in?igsh=MXdvMjgxNGViaHc0bQ=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      <div className="footer-copyright">
        <p>© 2024 Divine Realty. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
