// src/components/Header.js
import React, { useState } from "react";
import "./Header.css"; // We'll create this file for styling
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../assets/logo.png";

const Header = (props) => {
  const { activeView } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      {/* <div className="logo">Divine Realty</div> */}
      <div className="logo">
        <img src={logo} className="img-logo" alt={"logo"} />
      </div>
      <nav className={menuOpen ? "nav-menu active" : "nav-menu"}>
        <ul>
          <li>
            <button
              onClick={() => {
                toggleMenu();
                window?.scrollToServices("home");
              }}
            >
              <span className={activeView === "home" ? "active-button" : ""}>
                Home
              </span>
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                toggleMenu();
                window?.scrollToServices("service");
              }}
            >
              <span
                className={activeView === "services" ? "active-button" : ""}
              >
                Services
              </span>
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                toggleMenu();
                window?.scrollToServices("testimonials");
              }}
            >
              <span
                className={activeView === "testimonials" ? "active-button" : ""}
              >
                Testimonials
              </span>
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                toggleMenu();
                window?.scrollToServices("about");
              }}
            >
              <span className={activeView === "about" ? "active-button" : ""}>
                About Us
              </span>
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                toggleMenu();
                window?.scrollToServices("contact");
              }}
            >
              <span className={activeView === "contact" ? "active-button" : ""}>
                Contact Us
              </span>
            </button>
          </li>
        </ul>
      </nav>
      <div className="menu-icon" onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </header>
  );
};

export default Header;
