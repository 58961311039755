// src/App.js
import React, { useEffect, useRef, useState } from "react";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import ServicePage from "./components/ServicePage/ServicePage";
import AboutUs from "./components/AboutUs/AboutUs";
import Testimonials from "./components/Testimonials/Testimonials";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";

function App() {
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const testimonialRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const homePosition = homeRef.current.getBoundingClientRect().top;
      const servicesPosition = servicesRef.current.getBoundingClientRect().top;
      const testimoniaPosition = testimonialRef.current.getBoundingClientRect().top;
      const aboutPosition = aboutRef.current.getBoundingClientRect().top;
      const contactPosition = contactRef.current.getBoundingClientRect().top;
      const threshold = 100; // Adjust threshold as per your need
      if (homePosition < threshold && homePosition > -window.innerHeight / 2) {
        setActiveSection('home');
      } else if (servicesPosition < threshold && servicesPosition > -window.innerHeight / 2) {
        setActiveSection('services');
      } else if (testimoniaPosition < threshold && testimoniaPosition > -window.innerHeight / 2) {
        setActiveSection('testimonials');
      } else if (aboutPosition < threshold && aboutPosition > -window.innerHeight / 2) {
        setActiveSection('about');
      } else if (contactPosition < threshold && contactPosition > -window.innerHeight / 2) {
        setActiveSection('contact');
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  window.scrollToServices = (scrollView) => {
    switch (scrollView) {
      case "home":
        homeRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        servicesRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "testimonials":
        testimonialRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <div className="App">
      <Header activeView={activeSection} />
      <div ref={homeRef} className="services-section">
        <Home />
      </div>
      <div ref={servicesRef} className="services-section">
        <ServicePage />
      </div>
      <div ref={testimonialRef} className="services-section">
        <Testimonials />
      </div>
      <div ref={aboutRef} className="services-section">
        <AboutUs />
      </div>
      <div ref={contactRef} className="services-section">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}

export default App;
