// src/components/ServicePage.js
import React from "react";
import ServiceCard from "../ServiceCard/ServiceCard";
import "./ServicePage.css";
import rentalServiceImage from "../../assets/rental_service.jpg";
import sellingServiceImage from "../../assets/selling_service.jpg";
import buyingAssitanceServiceImg from "../../assets/buying_assitance_service.jpg";
import propertyLegalServiceImg from "../../assets/property_legal_service.jpg";
import home_interior_serivceImg from "../../assets/home_interior_serivce.jpg";

const services1 = [
  {
    imageSrc: rentalServiceImage,
    title: "Rental Services",
    description:
      "Find the perfect rental property with our extensive listings and tailored search capabilities. We make the process easy and efficient.",
  },
  {
    imageSrc: sellingServiceImage,
    title: "Selling Support",
    description:
      "We offer comprehensive services to help you sell your property quickly and at the best price. From market analysis to marketing strategies, we've got you covered.",
  },
  {
    imageSrc: buyingAssitanceServiceImg,
    title: "Buying Assistance",
    description:
      "Our team provides personalized assistance to help you find and purchase your ideal property. We work with you to understand your needs and preferences.",
  },
];

const services2 = [
  {
    imageSrc: home_interior_serivceImg,
    title: "Home Interior",
    description:
      "Elevate your living spaces with our Home Interior services, offering personalized design solutions that combine style, comfort, and functionality to create the home of your dreams.",
  },
  {
    imageSrc: propertyLegalServiceImg,
    title: "Property Legal Services",
    description:
      "Navigate property transactions with ease using our expert Property Legal Services, providing clear, reliable legal support for buying, selling, leasing, and dispute resolution.",
  },
];

const ServicePage = () => {
  return (
    <div className="service-container">
      <h1 className="txt-services">Services</h1>
      <div className="service-page">
        {services1.map((service, index) => (
          <ServiceCard
            key={index}
            imageSrc={service.imageSrc}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
      <div className="service-page2">
        {services2.map((service, index) => (
          <ServiceCard
            key={index}
            imageSrc={service.imageSrc}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </div>
  );
};

export default ServicePage;
