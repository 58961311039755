import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css"; // CSS file for styling
import client1 from "../../assets/client_profile_1.jpg";
import client2 from "../../assets/client_profile_2.jpg";
import client3 from "../../assets/client_profile_3.jpg";

const testimonials = [
  {
    name: "Pratik Hirpara",
    location: "Ahmedabad, Gujarat",
    rating: 5,
    feedback:
      "You’ll get the best of what you put in. The team is working at their best to provide us variety of spaces and homes. Had a great experience working with them. Thanks a lot.",
    image: client1, // Placeholder image or import actual
  },
  {
    name: "Ketan Prajapati",
    location: "Ahmedabad, Gujarat",
    rating: 5,
    feedback:
      "Amazing experience! They were so helpful and the process was so smooth. Highly recommend.",
    image: client2,
  },
  {
    name: "Bhakti Dave",
    location: "Ahmedabad, Gujarat",
    rating: 5,
    feedback:
      "The service was good, and the team was supportive throughout. I had a few concerns, but they were resolved quickly. Overall, a positive experience!",
    image: client3,
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Interval of scroll (3 seconds here)
    arrows: false,
  };

  return (
    <div className="container">
      <div className="testimonial-container">
        <h2 className="txt-what-client">What Our Client Says</h2>
        <h5 className="txt-our-client">
          Our Clients send us bunch of smilies with our services and we love
          them
        </h5>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <div className="testimonial-header">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="testimonial-image"
                />
                <div className="testimonial-info">
                  <h3>{testimonial.name}</h3>
                  <p>{testimonial.location}</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p>{testimonial.feedback}</p>
                <div className="rating">
                  {Array(testimonial.rating).fill("⭐️")}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
