import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./ContactUs.css"; // Create a CSS file for styling
import officeIcon from "../../assets/office_icon.png";
import phoneIcon from "../../assets/phone_icon.png";
import emailIcon from "../../assets/email_icon.png";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6aawtsq",
        "template_cpdc1vb",
        e.target,
        "COyR0Ck6KszToFZXd"
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
        },
        (error) => {
          alert("Failed to send the message, please try again.");
        }
      );

    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div className="contact-container">
      <div className="info-section">
        <div className="info-box">
          <img className="image-office" src={officeIcon} alt="Office icon" />
          <h4>OFFICE ADDRESS</h4>
          <p>
            T/F-8-B, Swastic Mall
            <br />
            Opp Jain Millian Soc, Sureliya,
            <br /> Amraiwadi, Ahmedabad - 380026.
          </p>
        </div>
        <div className="info-box">
          <img
            className="image-office"
            src={phoneIcon}
            alt="Phone number icon"
          />
          <h4>PHONE NUMBER</h4>
          <p>
            +91 (840)-192-6656
            <br />
          </p>
        </div>
        <div className="info-box">
          <img className="image-office" src={emailIcon} alt="Email icon" />
          <h4>EMAIL</h4>
          <p>info@divine-realty.in</p>
        </div>
        <div className="info-box">
          <img className="image-office" src={officeIcon} alt="Fax icon" />
          <h4>OPENING HOURS</h4>
          <p>Monday to Saturday: 9am to 8pm</p>
        </div>
      </div>
      <div className="form-section">
        <h2>Contact Us</h2>
        <form onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email address"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Your message"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
